import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Redirect from './Redirect.js'

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path = "/invite/:id" element={<Redirect/>}></Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
