import React, { useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Logo from './AppIcon.png';
import './Redirect.css';

export default function Redirect() {
  const deepLinkQuery = useParams(); 

  function TryOpen(query) {
    var url = "unitydl://pocketfriends.com?invite=" + query;
    window.location = url;

    setTimeout(function() {
      Redirect();
    }, 15000); 
  }

  function Redirect()
  {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      window.location = "https://campfirecozyfriends.com/";
    } else{
      window.location = "https://play.google.com/store/apps/details?id=com.campfire.aifriends";
    }
  }

  useEffect(() => {
    TryOpen(deepLinkQuery.id); 
  }, [deepLinkQuery]); 

  return (
    <div className="container redirect__container">
      <div>
        <img src={Logo} alt="campfire_logo" />
        <h1>Opening PocketFriends...</h1>
        <h2>Don't have PocketFriends installed?</h2>
        <button className="download_button" onClick={Redirect}>Download</button>
      </div>

    </div>
  );
}
